<template>
    <div>
        <el-main>
            暂无任何访问权限
        </el-main>
    </div>
</template>
<script>
export default {
    name: "Home",
    data() {
        return {};
    },
    mounted() {},
    methods: {},
    components: {},
};
</script>

<style lang="less" scoped>
</style>